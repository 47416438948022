exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-models-blog-dark-jsx": () => import("./../../../src/pages/models/blog-dark.jsx" /* webpackChunkName: "component---src-pages-models-blog-dark-jsx" */),
  "component---src-pages-models-blog-light-jsx": () => import("./../../../src/pages/models/blog-light.jsx" /* webpackChunkName: "component---src-pages-models-blog-light-jsx" */),
  "component---src-templates-blog-post-page-jsx": () => import("./../../../src/templates/BlogPostPage.jsx" /* webpackChunkName: "component---src-templates-blog-post-page-jsx" */),
  "component---src-templates-categories-page-jsx": () => import("./../../../src/templates/CategoriesPage.jsx" /* webpackChunkName: "component---src-templates-categories-page-jsx" */),
  "component---src-templates-category-page-jsx": () => import("./../../../src/templates/categoryPage.jsx" /* webpackChunkName: "component---src-templates-category-page-jsx" */),
  "component---src-templates-index-jsx": () => import("./../../../src/templates/Index.jsx" /* webpackChunkName: "component---src-templates-index-jsx" */)
}

